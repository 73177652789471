<template>
  <section class="catalog" id="menu">
    <div class="catalog__container">
      <div class="_container">
        <div class="catalog__body">
          <h2 class="title">{{ title }}</h2>
          <div class="catalog__text" v-text="text"></div>
        </div>
      </div>
      <div class="catalog__head">
        <ul class="catalog__nav">
          <li
            class="catalog__item"
            v-for="item in items"
            :key="item.id"
            @click="selectedCategory = item.real_name"
            :class="selectedCategory === item.real_name ? '_active' : ''"
          >
            {{ item.real_name }}
          </li>
        </ul>
        <!-- <button class="catalog__filter" @click="popupSort = !popupSort">
            <img src="@/assets/img/svg/filter.svg" alt="filter" />
            <span>Сортировка</span>
          </button> -->
        <popup-up-sorting v-if="popupSort" />
      </div>
      <div class="_container">
        <div class="dishes">
          <div
            class="dish"
            v-for="product in catalog[this.selectedCategory]"
            :key="product.id"
            :id="product.id"
            @click="
              (popupMore = true),
                (popupId = product.id),
                (isModalVisible = true)
            "
          >
            <div class="dish__view">
              <div class="dish__img _popup">
                <img :src="product.image" :alt="product.real_name" />
              </div>
            </div>
            <div class="dish__body">
              <h4 class="dish__title">{{ product.real_name.split("-")[0] }}</h4>
              <span class="dish__subtitle">{{
                product.real_name.split("-")[1]
              }}</span>
              <div v-if="product.intro_text !== 'None'" class="dish__text">
                {{ product.intro_text }}
              </div>
              <div class="dish__adds">
                <div class="dish__add">
                  <img src="@/assets/img/adds/Острое.png" alt="Острое" />
                </div>
                <div class="dish__add">
                  <img src="@/assets/img/adds/Конина.png" alt="Конина" />
                </div>
                <div class="dish__add">
                  <img src="@/assets/img/adds/Чеснок.png" alt="Чеснок" />
                </div>
                <div class="dish__add">
                  <img src="@/assets/img/adds/Новинка.png" alt="Новинка" />
                </div>
              </div>
              <div class="dish__bottom">
                <div class="dish__price">{{ product.price }} ₽</div>
                <button
                  class="dish__more"
                  @click="
                    (popupMore = true),
                      (popupId = product.id),
                      (isModalVisible = true)
                  "
                >
                  Подробнее
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <popup-mask
    :class="popupMore ? '_show' : ''"
    @close="(popupMore = false), (isModalVisible = false)"
    :id="popupId"
  />
  <login-popup
    v-if="popupLogin"
    @click="popupLogin = !popupLogin"
    @auth="userAuth"
    @close="closeLogin"
  />
</template>

<script>
import PopupUpSorting from "@/components/popups/PopupUpSorting.vue";
import PopupMask from "@/components/popups/PopupMask.vue";
import LoginPopup from "@/components/popups/LoginPopup.vue";
import axios from "axios";
export default {
  components: { PopupUpSorting, PopupMask, LoginPopup },
  name: "CatalogMain",
  created() {},
  data() {
    return {
      title: "Вкусно-татарский\nсловарь",
      text: `Наша кухня - это дорогие сердцу воспоминания: родная деревня, треск танцующих искр в печи, ласковые руки эби, её убаюкивающие колыбельные.\n\nПочувствуй нашу кухню такой, какой мы её запомнили из детства и какой мы её видим сейчас. Отправляйтесь в гастрономическое  путешествие из прошлого в будущее татарской кухни.`,
      catalog: {},
      popupSort: false,
      selectedCategory: "",
      popupMore: false,
      popupId: 0,
      popupLogin: false,
      isModalVisible: false,
      items: [],
    };
  },
  methods: {
    fetchCatalog() {
      axios
        .get("https://tcode.online/api/tatar/products/")
        .then((response) => {
          this.catalogParse(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    catalogParse(data) {
      for (let i = 0; i < data.length; i++) {
        if (
          Object.prototype.hasOwnProperty.call(
            this.catalog,
            data[i].category.real_name
          )
        ) {
          this.catalog[data[i].category.real_name].push(data[i]);
        } else {
          this.catalog[data[i].category.real_name] = [data[i]];
          if (this.selectedCategory === "") {
            this.selectedCategory = "Закуски";
          }
        }
      }
    },

    checkAuth() {
      const token = localStorage.getItem("token");

      if (token === null) {
        this.popupLogin = true;
      } else {
        window.location.replace("/account");
      }
    },

    closeLogin() {
      this.popupLogin = false;
    },

    async userAuth(token) {
      try {
        const response = await axios.get(
          "https://admin.tcode.online/api/projects/tatar/profile/",
          {
            headers: {
              Authorization: "Token " + token,
            },
          }
        );
        console.log(response.data);
        localStorage.setItem("token", token);
        localStorage.setItem("username", response.data.username);
        localStorage.setItem("name", response.data.first_name);
        this.$emit("authData", response.data);
        this.popupLogin = false;
        window.location.replace("/account");
      } catch (err) {
        // Если есть ошибка
        alert(err);
      }
    },
  },
  mounted() {
    this.fetchCatalog();
    axios
      .get("https://tcode.online/api/tatar/categories/")
      .then((response) => {
        console.log(response.data);
        this.items = response.data;
        this.items.shift();
      })
      .catch((error) => {
        console.log(error);
      });
  },
  watch: {
    isModalVisible: function () {
      if (this.isModalVisible) {
        document.documentElement.classList.add("hidden");
      } else {
        document.documentElement.classList.remove("hidden");
      }
    },
  },
};
</script>

<style lang="scss">
.dishes {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 25px;
  grid-row-gap: 37px;
  @media (max-width: $ms-laptop) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: $ms3) {
    grid-column-gap: 12px;
    grid-row-gap: 19px;
  }
  @media (max-width: $ms2) {
    grid-template-columns: repeat(2, 1fr);
  }
  .dish {
    background-color: #fff;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    cursor: pointer;
    font-family: "Montserrat";
    &__view {
      position: relative;
    }
    &__img {
      display: block;
      height: 252.63px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      overflow: hidden;
      cursor: pointer;
      @media (max-width: $ms3) {
        height: 180px;
      }
      @media (max-width: $ms4) {
        height: 120px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__watch {
      position: absolute;
      bottom: 1rem;
      right: 1rem;
      font-size: 14px;
      font-weight: 400;
      line-height: 19.1px;
      text-align: center;
      color: #fff;
      &:hover,
      &:focus {
        filter: opacity(0.5);
        transition: 0.2s ease 0.2s;
      }
      img {
        width: 20px;
        margin-left: 5px;
      }
    }
    &__body {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 20px 30px;
      @media (max-width: $ms3) {
        padding: 10px 20px;
      }
      @media (max-width: $ms4) {
        padding: 10px;
      }
    }
    &__title {
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      color: #2a374b;
      @media (max-width: $ms3) {
        font-size: 16px;
        line-height: 28px;
      }
    }
    &__subtitle {
      font-size: 18px;
      line-height: 28px;
      color: #5a5a5a;
      text-transform: capitalize;
      @media (max-width: $ms3) {
        font-size: 10px;
        line-height: 10px;
        font-style: italic;
        margin-bottom: 5px;
      }
    }
    &__text {
      font-size: 12px;
      line-height: 18px;
      color: #5a5a5a;
      margin-top: 1rem;
      margin-bottom: 1rem;
      flex: 1 1 auto;
      @media (max-width: $ms3) {
        font-size: 10px;
        margin-top: 0.5rem;
      }
      @media (max-width: $ms4) {
        line-height: 14px;
      }
    }
    &__adds {
      display: none;
    }
    &__add {
      height: 63px;
      margin: 1.5rem 1rem 0px 0px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &__bottom {
      margin-top: auto;
      display: flex;
      align-items: center;
    }
    &__price {
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      color: #2a374b;
      flex: 1 1 auto;
      @media (max-width: $ms3) {
        font-size: 16px;
        line-height: 28px;
      }
      @media (max-width: $ms4) {
        font-size: 14px;
      }
    }
    &__more {
      width: fit-content;
      height: 40px;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #2a374b;
      background: none;
      font-family: "Montserrat";
      background: #e5eefa;
      border-radius: 12px;
      padding: 0 10px;
      @media (max-width: $ms3) {
        font-size: 16px;
      }
      @media (max-width: $ms4) {
        font-size: 8px;
        height: 22px;
        border-radius: 4px;
        padding: 0 5px;
      }
      &:hover,
      &:focus {
        background: #2a374b;
        color: #e5eefa;
        transition: 0.2s ease 0.2s;
      }
    }
  }
  .discount {
    background: linear-gradient(
      90deg,
      rgba(24, 41, 55, 1) 100%,
      rgba(42, 55, 75, 1) 100%
    );
    height: 100%;
    &__icon {
      width: 40px;
      height: 40px;
      @media (max-width: $ms3) {
        width: 20px;
        height: 20px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &__title {
      font-family: "Century Gothic";
      font-size: 22px;
      font-weight: 400;
      line-height: 26.97px;
      color: #fff;
      margin-top: 10px;
      @media (max-width: $ms3) {
        font-size: 18px;
      }
    }
    &__text {
      font-size: 18px;
      font-weight: 300;
      line-height: 24.55px;
      color: #fff;
      margin-top: 1rem;
      @media (max-width: $ms3) {
        font-size: 14px;
      }
    }
    &__btn {
      width: 100%;
      height: 40px;
      border-radius: 40px;
      border: 1px solid #fefefe;
      background: none;
      font-size: 18px;
      font-weight: 400;
      line-height: 24.55px;
      color: #fff;
      margin-top: 3.5rem;
      @media (max-width: $ms3) {
        font-size: 16px;
        margin-top: 2rem;
      }
      @media (max-width: $ms4) {
        font-size: 14px;
      }
      @media (max-width: $ms5) {
        font-size: 10px;
      }
      &:hover,
      &:focus {
        background: #fff;
        color: #2a374b;
        transition: 0.2s ease 0.2s;
      }
    }
  }
}
.catalog {
  padding: 74px 0px 100px 0px;
  @media (max-width: $ms3) {
    padding: 50px 0px;
  }
  &__body {
    text-align: center;
    color: #000;
    width: 100%;
    margin: 0 auto;
    @media (max-width: $ms3) {
      max-width: unset;
    }
  }
  &__text {
    font-size: 18px;
    line-height: 28px;
    color: #000;
    white-space: pre-line;
    width: 50%;
    margin: 2rem auto 0;
    @media (max-width: $ms2) {
      width: 75%;
    }
    @media (max-width: $ms3) {
      font-size: 14px;
      line-height: 24px;
      margin-top: 1rem;
      justify-content: center;
      width: 100%;
    }
  }
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
    position: relative;
    padding: 0 50px;
    @media (max-width: $ms1) {
      flex-wrap: wrap;
    }
    @media (max-width: $ms3) {
      margin-top: 20px;
      padding: 0px 0px 0px 20px;
    }
  }
  &__nav {
    display: flex;
    grid-column-gap: 1rem;
    overflow-y: hidden;
    @media (max-width: $ms3) {
      grid-column-gap: 5px;
    }
    &::-webkit-scrollbar {
      width: 100%;
      height: 0;
    }
    &::-webkit-scrollbar-track {
      background: none;
    }
    &::-webkit-scrollbar-thumb {
      background: none;
    }
    ._active {
      background: #e5eefa;
      color: #2a374b;
    }
  }
  &__item {
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    position: relative;
    cursor: pointer;
    width: fit-content;
    height: 40px;
    border-radius: 8px;
    background: #f3f3f3;
    color: #5a5a5a;
    display: flex;
    align-items: center;
    padding: 0 15px;
    flex: 0 0 auto;
    &:hover,
    &:focus {
      background: #e5eefa;
      color: #2a374b;
      transition: 0.2s ease 0.2s;
    }
    @media (max-width: $ms1) {
      margin-bottom: 1rem;
    }
    @media (max-width: $ms3) {
      margin-top: 1rem;
      margin-bottom: 0;
      font-size: 14px;
      padding: 0 10px;
    }
    @media (max-width: $ms4) {
      font-size: 12px;
      padding: 0 5px;
    }
  }
  &__filter {
    background: none;
    display: flex;
    align-items: center;
    @media (max-width: $ms1) {
      width: 100%;
      justify-content: flex-end;
    }
    img {
      width: 30px;
      margin-right: 10px;
    }
    span {
      font-size: 22px;
      font-weight: 400;
      line-height: 30.01px;
      text-align: center;
      color: #000;
      @media (max-width: $ms3) {
        font-size: 14px;
      }
    }
    &:hover,
    &:focus {
      opacity: 0.5;
      transition: 0.2s ease 0.2s;
    }
  }
  .dishes {
    margin-top: 2rem;
  }
}
.popup {
  padding: 0 !important;

  &__inner {
    max-width: 512px;
    @media (max-width: $ms3) {
      max-width: 100% !important;
    }
  }
}
</style>
