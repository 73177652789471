<template>
  <div class="author-dishes" id="author-dishes">
    <div class="_container">
      <div class="author-dishes__container">
        <h2 class="title">{{ title }}</h2>
        <swiper
          class="author-dishes__videos"
          :modules="modules"
          :slides-per-view="4"
          :space-between="20"
          navigation
          :pagination="{ clickable: true }"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
          :loop="true"
          :breakpoints="{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }"
          :autoplay="{ delay: timeSlide }"
        >
          <swiper-slide
            class="author-dishes__video"
            v-for="video in videos"
            :key="video.id"
          >
            <video
              width="100%"
              height="100%"
              loop
              playsinline
              muted
            >
              <source
                :src="require(`@/assets/video/${video.src}`)"
                type="video/mp4"
                codecs="mp4"
              />
            </video>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination, A11y, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
export default {
  name: "AuthorDishes",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, A11y, Autoplay],
    };
  },
  data() {
    return {
      title: "Авторские блюда",
      poster: "poster.png",
      videos: [
        { id: 1, src: "1.mp4", time: 13 },
        { id: 2, src: "2.mp4", time: 12 },
        { id: 3, src: "3.mp4", time: 12 },
        { id: 4, src: "4.mp4", time: 15 },
      ],
      timeSlide: 0,
    };
  },
  mounted() {
    for (let i = 0; i < this.videos.length; i++) {
      this.timeSlide = this.videos[i].time * 1000 - 2000;
    }
    let videos = document.querySelectorAll(".author-dishes__video > video");
    function playVideos() {
      setTimeout(() => {
        videos[0].play(), videos[3].load();
      }, 0 + 2000);
      setTimeout(() => {
        videos[1].play(), videos[0].load();
      }, 13 * 1000 + 2000);
      setTimeout(() => {
        videos[2].play(), videos[1].load();
      }, 25 * 1000 + 2000);
      setTimeout(() => {
        videos[3].play(), videos[2].load();
      }, 37 * 1000 + 2000);
    }
    if (window.innerWidth < 768) {
      for (let j = 0; j < videos.length; j++) {
        videos[j].play();
      }
    } else {
      playVideos();
      setInterval(() => {
        playVideos();
      }, 52 * 1000 + 2000);
    }
  },
};
</script>

<style lang="scss">
.author-dishes {
  background-color: #e5eefa;
  padding-top: 60px;
  padding-bottom: 80px;
  @media (max-width: $ms3) {
    padding-top: 40px;
    padding-bottom: 100px;
  }

  &__videos {
    display: flex;
    height: 570px;
    @media (max-width: $ms3) {
      height: 554px;
    }
  }

  &__video {
    border-radius: 8px;
    overflow: hidden;
    background: #fff;
    margin-top: 30px;
    cursor: pointer;
    @media (max-width: $ms3) {
      margin-top: 20px;
    }
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .swiper .swiper-pagination {
    display: none;
    @media (max-width: $ms3) {
      display: flex;
    }
  }
  .swiper-pagination-bullet-active {
    background: #2a374b !important;
  }
  .swiper .swiper-pagination .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    background: rgb(42, 55, 75, 0.2);
  }
  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -50px;
    top: unset;
  }
}
</style>
