<template>
  <div class="wrapper" :class="$route.name + `-page`">
    <header-main
      :username="username"
      :name="name"
      :logo="logo"
      :user="user"
      @authData="getAuthData"
      @open-popup="openPopup"
      @close-popup="closePopup"
    />
    <!-- <header-main-test
      :username="username"
      :name="name"
      :logo="logo"
      :user="user"
      @authData="getAuthData"
    /> -->
    <router-view />
    <footer-main @open-popup="openPopup" @close-popup="closePopup" />
    <!-- <footer-main-test /> -->
  </div>
  <reserv-popup
    v-if="isPopup"
    @click="(isPopup = false), (isModalVisible = false)"
    @close-popup="closePopup"
  />
  <continue-book
    @open-popup="openPopup"
    @close-popup="closePopup"
    v-if="selectShop && date && $route.path === '/'"
  />
</template>

<script>
import HeaderMain from "@/components/HeaderMain.vue";
import FooterMain from "@/components/FooterMain.vue";
//import HeaderMainTest from "@/components/HeaderMainTest.vue";
//import FooterMainTest from "@/components/FooterMainTest.vue";
import ReservPopup from "@/components/popups/ReservPopup.vue";
import ContinueBook from "@/components/ContinueBook.vue";
import axios from "axios";
export default {
  components: {
    HeaderMain,
    FooterMain,
    //HeaderMainTest,
    //FooterMainTest,
    ReservPopup,
    ContinueBook,
  },
  name: "AccountPage",
  data() {
    return {
      login: `Личный кабинет`,
      ava: `line-md_account.png`,
      tel: `+7 965 456 66 43`,
      logo: `logo.png`,
      user: `svg/account.svg`,
      isPopup: false,
      btn: "Продолжить бронирование",
      img: "bell",
      width: 16,
      selectShop: localStorage.getItem("shop"),
      date: localStorage.getItem("date"),
      username: "",
      name: "",
      isModalVisible: false,
    };
  },
  methods: {
    getAuthData(data) {
      if (
        localStorage.getItem("username") !== "undefined" &&
        localStorage.getItem("username") !== ""
      ) {
        this.login = data.username;
      } else {
        this.login = "Личный кабинет";
      }
    },
    regiter() {},
    openPopup() {
      this.isPopup = true;
      this.isModalVisible = true;
    },
    closePopup() {
      this.isPopup = false;
      this.isModalVisible = false;
    },
  },
  mounted() {
    axios
      .get("https://admin.tcode.online/api/projects/tatar/profile/", {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        console.log(response.data);
        this.name = response.data.first_name;
        this.username = response.data.username;
      })
      .catch((error) => {
        console.log(error.response);
      });
    if (localStorage.getItem("token") !== null) {
      if (
        localStorage.getItem("username") !== "undefined" &&
        localStorage.getItem("username") !== ""
      ) {
        this.login = localStorage.getItem("first_name");
      } else {
        this.login = "Личный кабинет";
      }
    } else {
      this.login = "Личный кабинет";
    }
  },
  watch: {
    isModalVisible: function () {
      if (this.isModalVisible) {
        document.documentElement.classList.add("hidden");
      } else {
        document.documentElement.classList.remove("hidden");
      }
    },
  },
};
</script>
